<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제약사"
            >
              <b-form-input
                v-model="surveyData.clientName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제목"
            >
              <b-form-input
                v-model="surveyData.title"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- <b-form-group
              label="설문내용"
              label-for=""
            >
              <b-button
                variant="primary"
                class="mr-sm-50"
                @click="showModal = true"
              >
                <span>미리보기</span>
              </b-button>
              <b-modal
                v-model="showModal"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="surveyData.htmlContent"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>
            </b-form-group> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="처방 팝업 조건(약품)"
            >
              <b-form-input
                v-model="surveyData.condDrugCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="처방 팝업 조건(질병)"
            >
              <b-form-input
                v-model="surveyData.condDiseaseCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="처방 팝업 조건(검사)"
            >
              <b-form-input
                v-model="surveyData.condLabCodes"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="설문 게시 기간(Fr)"
            >
              <b-form-datepicker
                v-model="surveyData.startDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="설문 게시 기간(To)"
            >
              <b-form-datepicker
                v-model="surveyData.endDate"
                placeholder="게시기간(Fr)"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          cols="12"
          md="4"
        />
        <b-row>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="팝업 노출 수"
            >
              <b-form-input
                v-model="surveyData.popupNotiCnt"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="팝업 클릭 수"
            >
              <b-form-input
                v-model="surveyData.popupClickCnt"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="컨텐츠 조회수"
            >
              <b-form-input
                v-model="surveyData.viewCount"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-tabs
        pills
        class="m-2"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">결과정보</span>
          </template>

          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="d-flex align-items-center justify-content-start"
              >
                <h5 class="mr-50">
                  참여자 수 [{{ surveyData.finishCount }}] 명
                </h5>
                <b-button
                  variant="outline-dark"
                  class="btn-search"
                  @click.prevent="download(surveyData.liveInfoId)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                  />
                  <span> 다운로드</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>

import axios from '@axios'
import dayjs from 'dayjs'
import { BTab, BTabs } from 'bootstrap-vue'
import { saveAs } from "file-saver"
import { ref, onMounted } from "@vue/composition-api"
import { getUserData } from '@/auth/utils'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useToast } from "vue-toastification/composition"

export default {
  components: {
    BTab,
    BTabs,
  },
  computed: {

  },
  setup() {
    onMounted(() => {
      fetchDrugInfo()
    })
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const surveyData = ref({})
    const accessCode = ref(null)
    /// 미리보기 관련
    const showModal = ref(false)

    const fetchDrugInfo = () => {
      const userData = getUserData()
      accessCode.value = userData.accessCode
      axios.get(`/cl/survey/${accessCode.value}`)
        .then(rs => {
          const { data } = rs
          surveyData.value = data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const download = liveInfoId => {
      axios.get(`/cl/survey/download/${liveInfoId}`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])
          const fileName = `설문결과_${dayjs().format('YYYYMMDDHHmmss')}`

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    return {
      surveyData,
      showModal,
      download,
    }
  },
}
</script>

<style lang="scss">
#drugInfoPreview .modal-dialog {
  max-width: 1150px;
}
</style>
